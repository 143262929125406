export default {
  colors: {
    background: '#ffffff',
    text: '#040f0f',
    primary: '#FF8FB2',
    secondary: '#FFEE93',
    tertiary: '#FCF5C7',
    highlight: '#7ABACA',
  },
  fonts: {
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '750px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}
